import React, { useState } from 'react';

function GrievanceForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation or submit logic here
    // For simplicity, let's assume basic validation
    if (!formData.name || !formData.email || !formData.message) {
      setErrorMessage('Please fill out all fields.');
    } else {
      // Simulating form submission
      // Replace with actual form submission logic
      setSuccessMessage('Your grievance has been submitted successfully.');
      // Reset form data
      setFormData({ name: '', email: '', message: '' });
    }
  };

  return (
    <section className="drop-area pt-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="drop-item drop-img">
              <div className="drop-left" style={{ paddingTop: '20px' }}>
                <h3 style={{ textAlign: 'center' }}>Write Your Grievance.</h3>
                {errorMessage && <p className="message">{errorMessage}</p>}
                {successMessage && <p className="successmessage">{successMessage}</p>}
                <form onSubmit={handleSubmit} id="form">
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                          placeholder="Name"
                        />
                        <p id="namemessage" className="message"></p>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                          placeholder="Email"
                        />
                        <p id="emailmessage" className="message"></p>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="5"
                          required
                          placeholder="Your Grievance"
                        ></textarea>
                        <p id="textmessage" className="message"></p>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <button type="submit" className="drop-btn" id="submit">
                        Send
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden"></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GrievanceForm;
