import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    fname: '',
    mname: '',
    nationality: '',
    dob: '',
    gender: '',
    age: '',
    category: '',
    address: '',
    pincode: '',
    mobile: '',
    paddress: '',
    qualification: '',
    email: '',
    details: '',
    photograph: null,
    signature: null,
    tenthmarksheet: null,
    twelvethmarksheet: null
  });

  const [messages, setMessages] = useState({
    success: '',
    error: '',
    fieldname: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent via EmailJS
    const emailParams = {
      ...formData,
      photograph: formData.photograph ? formData.photograph.name : '',
      signature: formData.signature ? formData.signature.name : '',
      tenthmarksheet: formData.tenthmarksheet ? formData.tenthmarksheet.name : '',
      twelvethmarksheet: formData.twelvethmarksheet ? formData.twelvethmarksheet.name : ''
    };

    // Send the form data via EmailJS
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', emailParams, 'YOUR_USER_ID')
      .then(response => {
        console.log('Email sent successfully!', response);
        setMessages({ ...messages, success: 'Form submitted successfully!' });
        setFormData({
          name: '',
          fname: '',
          mname: '',
          nationality: '',
          dob: '',
          gender: '',
          age: '',
          category: '',
          address: '',
          pincode: '',
          mobile: '',
          paddress: '',
          qualification: '',
          email: '',
          details: '',
          photograph: null,
          signature: null,
          tenthmarksheet: null,
          twelvethmarksheet: null
        });
      })
      .catch(error => {
        console.error('Error sending email:', error);
        setMessages({ ...messages, error: 'Failed to submit form. Please try again.' });
      });
  };

  return (
    <div className="signup-area">
        {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Application</h2>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Application</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 pl-0">
            <div className="login-left">
              <img src="assets/img/login-bg.jpg" alt="Login" />
            </div>
          </div>
          <div className="col-lg-6 pb-100">
            <div className="signup-item">
              <div className="signup-head">
                <h3>Application form</h3>
              </div>
              <div className="signup-form">
                <p className="successmessage">{messages.success}</p>
                <p className="message">{messages.error} {messages.fieldname}</p>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name of Applicant"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          id="name"
                          required
                        />
                        <p id="namemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Father's Name"
                          name="fname"
                          value={formData.fname}
                          onChange={handleChange}
                          id="fname"
                          required
                        />
                        <p id="fnamemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mother's Name"
                          name="mname"
                          value={formData.mname}
                          onChange={handleChange}
                          id="mname"
                          required
                        />
                        <p id="mnamemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="nationality"
                          name="nationality"
                          value={formData.nationality}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Nationality</option>
                          <option value="Indian">Indian</option>
                        </select>
                        <p id="nationalitymessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Your Date of Birth"
                          name="dob"
                          value={formData.dob}
                          onChange={handleChange}
                          id="dob"
                          required
                        />
                        <p id="dobmessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="gender"
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <p id="gendermessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Age as on 31st Dec,2016"
                          name="age"
                          value={formData.age}
                          onChange={handleChange}
                          id="age"
                          required
                        />
                        <p id="agemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Category Gen. SCST OBC Others"
                          name="category"
                          value={formData.category}
                          onChange={handleChange}
                          id="category"
                          required
                        />
                        <p id="categorymessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Postal Address"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          id="address"
                          required
                        />
                        <p id="addressmessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Pin Code"
                          name="pincode"
                          value={formData.pincode}
                          onChange={handleChange}
                          id="pincode"
                          required
                        />
                        <p id="pincodemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          id="mobile"
                          required
                        />
                        <p id="mobilemessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Permanent Address"
                          name="paddress"
                          value={formData.paddress}
                          onChange={handleChange}
                          id="paddress"
                          required
                        />
                        <p id="paddressmessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Educational Qualification"
                          name="qualification"
                          value={formData.qualification}
                          onChange={handleChange}
                          id="qualification"
                          required
                        />
                        <p id="Qmessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          id="email"
                          required
                        />
                        <p id="emailmessage" className="message"></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <textarea
                          rows="4"
                          className="form-control"
                          placeholder="Other Information (If any)"
                          name="details"
                          value={formData.details}
                          onChange={handleChange}
                          id="details"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label>Select Photograph to upload:</label>
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          name="photograph"
                          id="pic1"
                          onChange={handleFileChange}
                          required
                        />
                        <p id="photograph" className="message"></p>
                      </div>
                      <ul className="guide-lines">
                        <li>Photo must have external dimensions of 35mm x 45mm (width x height)</li>
                        <li>Maximum File Size Supported 100KB</li>
                        <li>File format Supported .JPG</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <label>Select Signature to upload:</label>
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          name="signature"
                          id="pic2"
                          onChange={handleFileChange}
                          required
                        />
                        <p id="signature" className="message"></p>
                      </div>
                      <ul className="guide-lines">
                        <li>Signature must have external dimensions of 90mm x 40mm (width x height)</li>
                        <li>Maximum File Size Supported 100KB</li>
                        <li>File format Supported .JPG</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <label>Select 10th Marks Sheet to upload:</label>
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          name="tenthmarksheet"
                          id="pic3"
                          onChange={handleFileChange}
                          required
                        />
                        <p id="10marks" className="message"></p>
                      </div>
                      <ul className="guide-lines">
                        <li>PDF must have external dimensions of 8.27 × 11.69 inches (width x height).</li>
                        <li>Maximum File Size Supported 1MB</li>
                        <li>File format Supported .pdf</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <label>Select 12th Marks Sheet to upload:</label>
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control"
                          name="twelvethmarksheet"
                          id="pic4"
                          onChange={handleFileChange}
                          required
                        />
                        <p id="12marks" className="message"></p>
                      </div>
                      <ul className="guide-lines">
                        <li>PDF must have external dimensions of 8.27 × 11.69 inches (width x height).</li>
                        <li>Maximum File Size Supported 1MB</li>
                        <li>File format Supported .pdf</li>
                      </ul>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <button type="submit" className="btn signup-btn" id="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;
