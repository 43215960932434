import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import your images here world pharmacist day
import image1 from '../Assets/img/gallery/album1/a1p1.jpg';
import image2 from '../Assets/img/gallery/album1/a1p2.jpg';
import image3 from '../Assets/img/gallery/album1/a1p3.jpg';
import image4 from '../Assets/img/gallery/album1/a1p4.jpg';
import image5 from '../Assets/img/gallery/album1/a1p5.jpg';
import image6 from '../Assets/img/gallery/album1/a1p6.jpg';
// Import your images here teacher day
import image7 from '../Assets/img/gallery/album2/a2p1.jpg';
import image8 from '../Assets/img/gallery/album2/a2p2.jpg';
import image9 from '../Assets/img/gallery/album2/a2p3.jpg';
import image10 from '../Assets/img/gallery/album2/a2p4.jpg';
import image11 from '../Assets/img/gallery/album2/a2p5.jpg';
import image12 from '../Assets/img/gallery/album2/a2p6.jpg';
// Import your images here teacher day
import image13 from '../Assets/img/gallery/album3/a3p1.jpg';
import image14 from '../Assets/img/gallery/album3/a3p2.jpg';
import image15 from '../Assets/img/gallery/album3/a3p3.jpg';
import image16 from '../Assets/img/gallery/album3/a3p4.jpg';




// Album and Image structure for initial data
const initialAlbums = [
  {
    title: 'World Pharmacist Day',
    description: 'World Pharmacist Day was celebrated in the premises of Rajiv Gandhi Institute of Pharmacy.',
    coverImage: image13,  // Use imported image
    images: [
      { src: image13, alt: 'Teacher Day 1' },
      { src: image14, alt: 'Teacher Day 2' },
      { src: image15, alt: 'Teacher Day 3' },
      { src: image16, alt: 'Teacher Day 10' },
      
      
    ],
  },
  {
    title: 'Teacher day',
    description: 'Teacher Day was celebrated in the premises of Rajiv Gandhi Institute of Pharmacy.',
    coverImage: image7,  // Use imported image
    images: [
      { src: image7, alt: 'Teacher Day 1' },
      { src: image8, alt: 'Teacher Day 2' },
      { src: image9, alt: 'Teacher Day 3' },
      { src: image10, alt: 'Teacher Day 10' },
      { src: image11, alt: 'Teacher Day 11' },
      { src: image12, alt: 'Teacher Day 12' },
      
    ],
  },
  {
    title: 'World Pharmacist Day',
    description: 'World Pharmacist Day was celebrated in the premises of Rajiv Gandhi Institute of Pharmacy.',
    coverImage: image1,  // Use imported image
    images: [
      { src: image1, alt: 'Pharmacist Day 1' },
      { src: image2, alt: 'Pharmacist Day 2' },
      { src: image3, alt: 'Pharmacist Day 3' },
      { src: image4, alt: 'Pharmacist Day 4' },
      { src: image6, alt: 'Pharmacist Day 5' },
    ],
  },
];

function Gallery() {
  const [albums, setAlbums] = useState(initialAlbums); // Store albums
  const [currentAlbum, setCurrentAlbum] = useState(null); // Track current album
  const [currentImage, setCurrentImage] = useState(null); // Track current image

  // Open selected album
  const openAlbum = (album) => {
    setCurrentAlbum(album);
  };

  // Close album view
  const closeAlbum = () => {
    setCurrentAlbum(null);
  };

  // Open selected image in a modal
  const openImage = (image) => {
    setCurrentImage(image);
  };

  // Close image modal
  const closeImage = () => {
    setCurrentImage(null);
  };

  return (
    <>
    <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Gallery</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    <div className="container mt-3">
      
      {/* Display Albums with cover image, title, and description */}
      <div className="row">
  {albums.map((album, index) => (
    <div className="col-lg-4 col-md-6 mb-4" key={index}>
      <div
        className="card h-100"
        onClick={() => openAlbum(album)}
        style={{ cursor: 'pointer' }}
      >
        <img 
          className="card-img-top img-fluid" 
          src={album.coverImage} 
          alt={album.title} 
          style={{ objectFit: 'cover', height: '200px' }} // Adjust height and object-fit
        />
        <div className="card-body">
          <h5 className="card-title">{album.title}</h5>
          <p className="card-text">{album.description}</p>
        </div>
      </div>
    </div>
  ))}
      </div>

      {/* Album Modal */}
      {currentAlbum && (
        <div 
          className="modal fade show" 
          style={{ 
            display: 'block', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: currentImage ? 1040 : 1050,  // Lower z-index when image is opened
          }} 
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{currentAlbum.title}</h5>
                <button type="button" className="btn-close" onClick={closeAlbum}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {currentAlbum.images.map((image, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                      <img
                        className="img-fluid"
                        src={image.src}
                        alt={image.alt}
                        style={{ objectFit: 'cover', height: '200px', cursor: 'pointer'}}
                        onClick={() => openImage(image)} // Open image modal on click
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" style={{ display: 'none' }}></div>
        </div>
      )}

      {/* Image Modal */}
      {currentImage && (
        <>
          {/* Fade background only */}
          <div 
            className="fade-background" 
            style={{ 
              position: 'fixed', 
              top: 0, 
              left: 0, 
              width: '100%', 
              height: '100%', 
              backgroundColor: 'rgba(0, 0, 0, 0.8)', 
              zIndex: 1055, // Backdrop with higher z-index than album but lower than image modal
            }} 
          ></div>
          
          {/* Image Modal */}
          <div 
            className="modal fade show" 
            style={{ 
              display: 'block', 
              zIndex: 1060,  // Higher z-index for image modal to appear in front
            }} 
            tabIndex="-1"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h5 className="modal-title">{currentImage.alt}</h5> */}
                  <button type="button" className="btn-close" onClick={closeImage}></button>
                </div>
                <div className="modal-body text-center">
                  <img 
                    className="img-fluid"  
                    style={{ 
                      width: '100%', 
                      height: 'auto', 
                      objectFit: 'contain',
                    }}
                    src={currentImage.src} 
                    alt={currentImage.alt} 
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    </>
  );
  
}

export default Gallery;
