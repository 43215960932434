import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../Assets/navstyle.css'; // Adjust the path if necessary
import syllabus from '../../Assets/pdf/RGIPSyllabus.pdf';
import form from '../../Assets/pdf/Applicationform.pdf';
import prosspucts from '../../Assets/pdf/RGIPProspectus2024.pdf';

function CustomNavbar() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Item>
            <Nav.Link as={Link} to="/" className="nav-link text-center">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="About Us" id="about-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Introducation">Introduction</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Vision">Vision and Mission</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Chairman">Chairman Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Director">Director Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Principal">Principal Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Facilities" >Our Facilities</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Rules-regulations">Rules and regulations</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Grievance">Grievance Cell</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={Link} to="/Facilities" className="nav-link text-center">Our Facilities</Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <NavDropdown title="Course" id="course-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/D-pharmacy">D. Pharmacy</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/B-pharmacy">B. Pharmacy</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/M-pharmacy">M. Pharmacy</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Admission" id="admission-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Application">Application</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Gallery" id="gallery-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item as={Link} to="/Gallery">Photo Gallery</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Videogallery">Video Gallery</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/Alumni" className="nav-link text-center">Alumni</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Downloads" id="downloads-dropdown" className="nav-link navt text-center">
              <NavDropdown.Item href={form} target="_blank">Admission Form</NavDropdown.Item>
              <NavDropdown.Item href={syllabus} target="_blank">Syllabus</NavDropdown.Item>
              <NavDropdown.Item href={prosspucts} target="_blank">Prospectus</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/Contact" className="nav-link text-center">Contact Us</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
